import { gql } from '@apollo/client';

export const MEDIA_FRAGMENT = gql`
  fragment MediaFragment on UploadFileEntity {
    id
    attributes {
      url
      alternativeText
      caption
      createdAt
      updatedAt
      formats
      provider
      __typename
    }
  }
`;
// lol

export const LANGUAGE_FRAGMENT = gql`
  fragment LanguageFragment on LanguageEntity {
    id
    attributes {
      name
      short
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const TRANSLATION_FRAGMENT = gql`
  ${LANGUAGE_FRAGMENT}
  fragment TranslationFragment on TranslationEntity {
    id
    attributes {
      translation
      language {
        data {
          ...LanguageFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const USER_DETAILS_FRAGMENT = gql`
  fragment UserDetailsFragment on UserdetailEntity {
    id
    attributes {
      company_name
      full_name
      street
      house_number
      partita_iva
      postal_code
      city
      country
      tax_number
      recipient_code
      pec
      extra
      deadline
      tel
      user {
        data {
          id
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const USER_DETAILS_FRAGMENT_SMALL = gql`
  fragment UserDetailsFragmentSmall on UserdetailEntity {
    id
    attributes {
      company_name
      full_name
      street
      house_number
      partita_iva
      postal_code
      city
      country
      tax_number
      recipient_code
      pec
      extra
      deadline
      tel
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const DISCOUNT_FRAGMENT = gql`
  ${TRANSLATION_FRAGMENT}
  fragment DiscountFragment on DiscountEntity {
    id
    attributes {
      range
      name {
        data {
          ...TranslationFragment
        }
      }
      users {
        data {
          id
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const DISCOUNT_FRAGMENT_SMALL = gql`
  ${TRANSLATION_FRAGMENT}
  fragment DiscountFragmentSmall on DiscountEntity {
    id
    attributes {
      range
      name {
        data {
          ...TranslationFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const VAT_FRAGMENT = gql`
  ${TRANSLATION_FRAGMENT}
  fragment VatFragment on VatEntity {
    id
    attributes {
      value
      active
      name {
        data {
          ...TranslationFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ZONE_FRAGMENT = gql`
  fragment ZoneFragment on DeliveryZoneEntity {
    id
    attributes {
      name
      bakery {
        data {
          id
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ZONE_FRAGMENT_SMALL = gql`
  fragment ZoneFragmentSmall on DeliveryZoneEntity {
    id
    attributes {
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const BILL_SETTINGS_FRAGMENT = gql`
  ${MEDIA_FRAGMENT}
  fragment BillSettingFragment on BillSettingEntity {
    id
    attributes {
      e_bill_data
      pdf_footer
      pdf_header
      pdf_logo {
        data {
          ...MediaFragment
        }
      }
      bill_number_format
      current_bill_number
      dd_number_format
      current_dd_number
      bakery {
        data {
          id
        }
      }
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const BILL_SETTINGS_FRAGMENT_SMALL = gql`
  ${MEDIA_FRAGMENT}
  fragment BillSettingFragmentSmall on BillSettingEntity {
    id
    attributes {
      e_bill_data
      pdf_footer
      pdf_header
      pdf_logo {
        data {
          ...MediaFragment
        }
      }
      bill_number_format
      current_bill_number
      dd_number_format
      current_dd_number
      bakery {
        data {
          id
        }
      }
      updatedAt
      createdAt
      __typename
    }
  }
`;

export const PLAN_FRAGMENT = gql`
  ${TRANSLATION_FRAGMENT}
  ${MEDIA_FRAGMENT}
  fragment PlanFragment on PlanEntity {
    id
    attributes {
      cost_per_month
      thumb {
        data {
          ...MediaFragment
        }
      }
      user_restriction
      name {
        data {
          ...TranslationFragment
        }
      }
      description {
        data {
          ...TranslationFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const HOLIDAY_FRAGMENT = gql`
  fragment HolidayFragment on HolidayEntity {
    id
    attributes {
      name
      from
      to
      bakery {
        data {
          id
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const HOLIDAY_FRAGMENT_SMALL = gql`
  fragment HolidayFragmentSmall on HolidayEntity {
    id
    attributes {
      name
      from
      to
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const PRICE_LIST_FRAGMENT = gql`
  fragment PriceListFragment on PriceListEntity {
    id
    attributes {
      name
      bakery {
        data {
          id
        }
      }
      visible_for {
        data {
          id
        }
      }
      default
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const PRICE_LIST_FRAGMENT_SMALL = gql`
  fragment PriceListFragmentSmall on PriceListEntity {
    id
    attributes {
      name
      default
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const PRODUCT_PRICE_LIST_FRAGMENT = gql`
  ${PRICE_LIST_FRAGMENT}
  fragment ProductPriceList on ProductPriceListEntity {
    id
    attributes {
      gram_price
      product {
        data {
          id
        }
      }
      price_list {
        data {
          ...PriceListFragment
        }
      }
      createdAt
      __typename
    }
  }
`;
export const PRODUCT_PRICE_LIST_FRAGMENT_SMALL = gql`
  ${PRICE_LIST_FRAGMENT_SMALL}
  fragment ProductPriceListSmall on ProductPriceListEntity {
    id
    attributes {
      gram_price
      price_list {
        data {
          ...PriceListFragmentSmall
        }
      }
      createdAt
      __typename
    }
  }
`;

export const USER_FRAGMENT = gql`
  ${LANGUAGE_FRAGMENT}
  ${USER_DETAILS_FRAGMENT_SMALL}
  ${ZONE_FRAGMENT}
  ${ZONE_FRAGMENT_SMALL}
  ${HOLIDAY_FRAGMENT_SMALL}
  ${BILL_SETTINGS_FRAGMENT_SMALL}
  ${MEDIA_FRAGMENT}
  fragment UserFragment on UsersPermissionsUserEntity {
    id
    attributes {
      username
      email
      confirmed
      blocked
      active_from
      active_to
      is_host
      is_bakery
      check_orders
      host {
        data {
          id
        }
      }
      guests {
        data {
          id
        }
      }
      bakery {
        data {
          id
        }
      }
      hosts {
        data {
          id
        }
      }
      language {
        data {
          ...LanguageFragment
        }
      }
      discount {
        data {
          id
        }
      }
      userdetail {
        data {
          ...UserDetailsFragmentSmall
        }
      }
      profile_picture {
        data {
          ...MediaFragment
        }
      }
      plan {
        data {
          id
        }
      }
      delivery_zones {
        data {
          ...ZoneFragmentSmall
        }
      }
      delivery_zone {
        data {
          ...ZoneFragment
        }
      }
      holidays {
        data {
          ...HolidayFragmentSmall
        }
      }
      ignore_deadline
      create_bills
      delivers_saturday
      delivers_sunday
      delivery_cost
      delivery_cost_min
      no_delivery_cost
      bill_setting {
        data {
          ...BillSettingFragmentSmall
        }
      }
      takeaway
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const USER_FRAGMENT_DASHBOARD = gql`
  ${MEDIA_FRAGMENT}
  fragment UserFragmentDashboard on UsersPermissionsUserEntity {
    id
    attributes {
      username
      email
      confirmed
      blocked
      is_host
      is_bakery
      userdetail {
        data {
          id
          attributes {
            company_name
          }
        }
      }
      createdAt
      guests {
        data {
          id
        }
      }
      profile_picture {
        data {
          ...MediaFragment
        }
      }
      __typename
    }
  }
`;

export const USER_FRAGMENT_SMALL = gql`
  ${USER_DETAILS_FRAGMENT_SMALL}
  ${BILL_SETTINGS_FRAGMENT_SMALL}
  ${MEDIA_FRAGMENT}
  fragment UserFragmentSmall on UsersPermissionsUserEntity {
    id
    attributes {
      username
      email
      confirmed
      blocked
      active_from
      active_to
      is_host
      is_bakery
      check_orders
      discount {
        data {
          id
        }
      }
      userdetail {
        data {
          ...UserDetailsFragmentSmall
        }
      }
      profile_picture {
        data {
          ...MediaFragment
        }
      }
      ignore_deadline
      create_bills
      delivers_saturday
      delivers_sunday
      bill_setting {
        data {
          ...BillSettingFragmentSmall
        }
      }
      takeaway
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const PRODUCT_FRAGMENT = gql`
  ${TRANSLATION_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PRODUCT_PRICE_LIST_FRAGMENT_SMALL}
  fragment ProductFragment on ProductEntity {
    id
    attributes {
      name {
        data {
          ...TranslationFragment
        }
      }
      vat {
        data {
          id
        }
      }
      gram_price
      description {
        data {
          ...TranslationFragment
        }
      }
      approx_weight
      price_lists {
        data {
          ...ProductPriceListSmall
        }
      }
      have_to_weight
      thumb {
        data {
          ...MediaFragment
        }
      }
      bakery {
        data {
          id
        }
      }
      active
      easyback_kodex
      display_order
      deleted
      available_days
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const PRODUCT_FRAGMENT_SMALL = gql`
  ${TRANSLATION_FRAGMENT}
  ${VAT_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PRODUCT_PRICE_LIST_FRAGMENT_SMALL}
  fragment ProductFragmentSmall on ProductEntity {
    id
    attributes {
      name {
        data {
          ...TranslationFragment
        }
      }
      vat {
        data {
          ...VatFragment
        }
      }
      gram_price
      approx_weight
      price_lists {
        data {
          ...ProductPriceListSmall
        }
      }
      have_to_weight
      thumb {
        data {
          ...MediaFragment
        }
      }
      bakery {
        data {
          id
        }
      }
      active
      easyback_kodex
      display_order
      deleted
      createdAt
      updatedAt
      available_days
      __typename
    }
  }
`;

export const ORDERED_PRODUCT_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT_SMALL}
  fragment OrderedProductFragment on OrderedProductEntity {
    id
    attributes {
      amount
      current_vat
      ordered_by {
        data {
          id
          attributes {
            username
          }
        }
      }
      current_gram_price
      current_approx_weight
      measured_weight
      current_display_price
      order {
        data {
          id
        }
      }
      base_product {
        data {
          ...ProductFragmentSmall
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ORDERED_PRODUCT_FRAGMENT_SMALL = gql`
  ${PRODUCT_FRAGMENT_SMALL}
  fragment OrderedProductFragmentSmall on OrderedProductEntity {
    id
    attributes {
      amount
      current_vat
      ordered_by {
        data {
          id
          attributes {
            username
          }
        }
      }
      current_gram_price
      current_approx_weight
      measured_weight
      current_display_price
      base_product {
        data {
          ...ProductFragmentSmall
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const HOST_PRODUCT_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  ${USER_FRAGMENT}
  fragment HostProductFragment on HostProductEntity {
    id
    attributes {
      host {
        data {
          ...UserFragment
        }
      }
      display_price
      base_product {
        data {
          ...ProductFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const BASKET_PRODUCT_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  fragment BasketProductFragment on BasketProductEntity {
    id
    attributes {
      amount
      user {
        data {
          id
        }
      }
      current_display_price
      base_product {
        data {
          ...ProductFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ABO_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment AboFragment on AboEntity {
    id
    attributes {
      products {
        data {
          id
          attributes {
            amount
            createdAt
            updatedAt
            __typename
          }
        }
      }
      active
      interval
      user {
        data {
          ...UserFragment
        }
      }
      last_order
      prev_order {
        data {
          id
          attributes {
            deadline
            createdAt
            updatedAt
            __typename
          }
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const ORDER_FRAGMENT = gql`
  ${ORDERED_PRODUCT_FRAGMENT_SMALL}
  ${USER_FRAGMENT_SMALL}
  fragment OrderFragment on OrderEntity {
    id
    attributes {
      deadline
      message
      final_price
      last_modified
      host {
        data {
          ...UserFragmentSmall
        }
      }
      public_id
      bill {
        data {
          id
          attributes {
            bill_number
            paid
          }
        }
      }
      ordered_products {
        data {
          ...OrderedProductFragmentSmall
        }
      }
      accepted
      takeaway
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ORDER_FRAGMENT_DASHBOARD = gql`
  fragment OrderFragmentDashboard on OrderEntity {
    id
    attributes {
      deadline
      host {
        data {
          id
          attributes {
            username
            userdetail {
              data {
                id
                attributes {
                  company_name
                }
              }
            }
          }
        }
      }
      createdAt
      public_id
      final_price
      bill {
        data {
          id
          attributes {
            bill_number
            paid
          }
        }
      }
      __typename
    }
  }
`;
export const ORDER_FRAGMENT_BAKERY_SMALL = gql`
  fragment OrderFragmentBakerySmall on OrderEntity {
    id
    attributes {
      deadline
      public_id
      accepted
      takeaway
      createdAt
      final_price
      host {
        data {
          id
          attributes {
            is_bakery
            is_host
            email
            username
            userdetail {
              data {
                id
                attributes {
                  full_name
                  company_name
                }
              }
            }
          }
        }
      }
      message
      bill {
        data {
          id
          attributes {
            bill_number
            paid
          }
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const ORDER_FRAGMENT_FULL = gql`
  ${ORDERED_PRODUCT_FRAGMENT_SMALL}
  ${USER_FRAGMENT}
  fragment OrderFragmentFull on OrderEntity {
    id
    attributes {
      deadline
      message
      last_modified
      host {
        data {
          ...UserFragment
        }
      }
      public_id
      bill {
        data {
          id
          attributes {
            bill_number
            paid
          }
        }
      }
      ordered_products {
        data {
          ...OrderedProductFragmentSmall
        }
      }
      accepted
      takeaway
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const BILL_FRAGMENT = gql`
  ${ORDER_FRAGMENT}
  fragment BillFragment on BillEntity {
    id
    attributes {
      bill_number
      bill_date
      bakery {
        data {
          id
        }
      }
      client {
        data {
          id
          attributes {
            username
            is_host
            email
          }
        }
      }
      sent
      amount
      paid
      orders {
        data {
          ...OrderFragment
        }
      }
      pdf {
        data {
          ...MediaFragment
        }
      }
      xml {
        data {
          ...MediaFragment
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const MESSAGE_FRAGMENT = gql`
  ${USER_FRAGMENT}
  fragment MessageFragment on MessageEntity {
    id
    attributes {
      title
      message
      recipients {
        data {
          ...UserFragment
        }
      }
      writer {
        data {
          ...UserFragment
        }
      }
      reply_to {
        data {
          id
        }
      }
      state
      createdAt
      updatedAt
      __typename
    }
  }
`;
