import * as React from 'react';
import {Container, Row} from 'reactstrap';

type LoadingProps = { small?: boolean };

const Loading: React.FC<LoadingProps> = (props) => {
    const spinner = (
        <Row className="justify-content-center">
            <div className="spinner-border text-dark big" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </Row>
    );
    const {small} = props;

    return small ? (
        spinner
    ) : (
        <div className="app flex-row align-items-center">
            <Container>{spinner}</Container>
        </div>
    );
};
export default Loading;
