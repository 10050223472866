import * as React from 'react';

class SidebarMinimizer extends React.Component {
    sidebarMinimize(): void {
        document.body.classList.toggle('sidebar-minimized');
    }

    brandMinimize(): void {
        document.body.classList.toggle('brand-minimized');
    }

    render(): React.JSX.Element {
        return (
            <button
                title="Minimize"
                className="sidebar-minimizer sidebar-toggle"
                type="button"
                onClick={() => {
                    this.sidebarMinimize();
                    this.brandMinimize();
                }}
            />
        );
    }
}

export default SidebarMinimizer;
