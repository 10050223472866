import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import Cookie from 'js-cookie';
import { cache } from './graphql/cache';
import { link } from './graphql/link';
import Main from './views/Containers/Main';
import Loading from './views/Components/Loading';
import { translate } from './config/Translator';
import { LanguageISO } from './config/types';
import { APP_NAME } from './config';

require('./scss/style.scss');

type AppState = {
  client: ApolloClient<NormalizedCacheObject> | null;
};
export default class App extends React.Component<unknown, AppState> {
  private language: LanguageISO = 'de';

  constructor(props: unknown) {
    super(props);
    this.state = {
      client: null,
    };
    document.title = APP_NAME;
  }

  componentDidMount(): void {
    const descMeta = document.querySelector('meta[name="description"]');
    if (descMeta !== null) {
      descMeta.setAttribute('content', translate('keywords', this.language));
    }
    let deviceLanguage = navigator.language;
    if (
      typeof deviceLanguage !== 'undefined' &&
      deviceLanguage.indexOf('_') !== -1
    ) {
      [deviceLanguage] = deviceLanguage.split('_');
      if (
        deviceLanguage === 'de' ||
        deviceLanguage === 'it' ||
        deviceLanguage === 'en'
      ) {
        this.language = deviceLanguage;
      }
    }
    const cookieLang = Cookie.get('sw_lang');
    if (
      cookieLang &&
      (cookieLang === 'de' || cookieLang === 'it' || cookieLang === 'en')
    ) {
      this.language = cookieLang;
    }
    this.setState({
      client: new ApolloClient({
        cache,
        //      resolvers: resolvers,
        link,
      }),
    });
  }

  render(): React.JSX.Element {
    const { client } = this.state;
    if (client === null) {
      return <Loading />;
    }
    return (
      <ApolloProvider client={client}>
        <Main language={this.language} />
      </ApolloProvider>
    );
  }
}
