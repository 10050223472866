import * as React from 'react';
import { Context } from 'react';
import { Nav, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { BAKERY, UserContext, UserContextTypeLoggedIn } from '../../../config';
import { translate } from '../../../config/Translator';

export default class HeaderLoggedOut extends React.Component {
  declare context: React.ContextType<Context<UserContextTypeLoggedIn>>;

  mobileSidebarToggle(e: React.MouseEvent): void {
    e.preventDefault();
    global.document.body.classList.toggle('sidebar-mobile-show');
  }

  render(): React.JSX.Element {
    const { language } = this.context;
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon" />
        </NavbarToggler>
        <NavbarBrand href="/" />
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/login">{translate('login', language)}</NavLink>
          </NavItem>
          {/*
          <NavItem className="px-3">
            <NavLink href="/register">
              {translate('register', language)}
            </NavLink>
          </NavItem>
          */}
          {BAKERY === 'SlowVita' && (
            <NavItem className="px-3">
              <NavLink href="/plans">{translate('prices', language)}</NavLink>
            </NavItem>
          )}
          <NavItem className="px-3">
            <NavLink href="/impressum">{translate('about', language)}</NavLink>
          </NavItem>
        </Nav>
      </header>
    );
  }
}
HeaderLoggedOut.contextType = UserContext;
