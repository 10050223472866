import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { mutations, queries } from './resolvers';
import { errorLogging, formatGraphqlDate } from '../config/utils';
import {
  MutationKeys,
  MutationParamTypes,
  MutationReturnTypes,
  QueryKeys,
  QueryParamTypes,
  QueryReturnTypes,
} from './types';
import { DateType } from '../config/types';

function isDate(elem: unknown): elem is Date {
  return elem !== null && typeof elem === 'object' && elem instanceof Date;
}

function formatDateForParams<T extends { [U in string]: unknown }>(
  params: T | undefined,
): { [U in string]: unknown } | undefined {
  if (typeof params === 'undefined') {
    return undefined;
  }
  const paramsNew = {};
  const keys = Object.keys(params);
  keys.forEach((key) => {
    const elem = params[key];
    if (isDate(elem)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      paramsNew[key] = formatGraphqlDate(elem, true, true);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      paramsNew[key] = elem;
    }
  });
  return paramsNew;
}

export function formatOnlyDateForParams(date: Date | string): DateType {
  return formatGraphqlDate(date, false, true).split('T')[0];
}

export declare type NetworkErrorType =
  | 'page_error'
  | 'server_error'
  | 'network_unreachable'
  | 'wrong_data'
  | 'not_allowed';

export interface NetworkError {
  type: NetworkErrorType;
  payload: unknown;
}

export function handleError(error: NetworkError): void {
  errorLogging(error);
}

export function query<T extends QueryKeys>(
  client: ApolloClient<NormalizedCacheObject>,
  queryDocument: T,
  params: QueryParamTypes<T>,
  cache = false,
): Promise<QueryReturnTypes<T>> {
  return new Promise((resolve) => {
    client
      .query({
        query: queries[queryDocument].query,
        variables: formatDateForParams(params),
        fetchPolicy: cache ? 'cache-first' : 'no-cache',
      })
      .then(
        (r) => {
          errorLogging(r);
          resolve(queries[queryDocument].resolver(r));
        },
        (error) => {
          errorLogging(error);
          resolve(undefined); // TODO look what error and give a Error type
        },
      );
  });
}

export function mutate<T extends MutationKeys>(
  client: ApolloClient<NormalizedCacheObject>,
  mutation: T,
  params: MutationParamTypes<T>,
): Promise<MutationReturnTypes<T>> {
  return new Promise((resolve) => {
    client
      .query({
        query: mutations[mutation].mutation,
        variables: formatDateForParams(params),
        fetchPolicy: 'no-cache',
      })
      .then(
        (r) => {
          resolve(mutations[mutation].resolver(r));
        },
        (error) => {
          errorLogging(error);
          resolve(undefined); // TODO look what error and give a Error type
        },
      );
  });
}
